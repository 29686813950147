.TodoSearchbar {
  height: 3em;
  max-height: 40px;
  -webkit-text-fill-color: #222;
  color: #222;
  font-size: 1.5rem;
  font-family: "Lato", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-weight: 700;
}

.TodoSearchbar::placeholder {
  -webkit-text-fill-color: rgb(230, 225, 219);
  color: rgb(230, 225, 219);
  font-style: italic;
  font-family: "Lato", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-weight: 400;
}

.TodoSearchbar:focus {
  outline: none;
}
