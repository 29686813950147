.AddTaskModal-container {
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.notShowing {
  display: none;
}

.AddTaskModal {
  background-color: rgb(18, 10, 71);
  width: 90%;
  height: 50%;
  max-width: 500px;
  max-height: 850px;
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-direction: column;
  overflow: hidden;
}

.AddTaskModal form {
  width: max-content;
  margin: 15px;
  max-width: 470px;
  width: 90%;
}

.addTaskForm {
  flex-grow: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.modal-buttons {
  min-height: 45px;
  flex-grow: 1;
  color: white;
}

label {
  font-family: "Lato", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-size: 2em;
  margin: 0;
  padding: 15px 0 5px;
}

textarea {
  width: calc(100% - 5px);
  height: 50%;
  resize: none;
  /* padding: 10px; */
  -webkit-text-fill-color: #222;
  color: #222;
  font-size: 2em;
  font-family: "Lato", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-weight: 700;
  display: inline-block;
  vertical-align: middle;
}

textarea::placeholder {
  -webkit-text-fill-color: rgb(210, 205, 199);
  color: rgb(210, 205, 199);
  font-style: italic;
  font-weight: 400;
}

textarea:focus {
  outline: none;
}

@media only screen and (min-width: 600px) {
  .AddTaskModal {
    border-radius: 1em;
  }
  .modal-buttons {
    min-height: 50px;
  }
}
@media only screen and (max-height: 500px) {
  .AddTaskModal {
    height: 90%;
    overflow: auto;
  }
}
