*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 26px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}
.TodoList:hover {
  color: rgba(0, 0, 0, 0.3);
}

.TodoList {
  background-color: rgba(18, 10, 71, 0.9);
  padding: 0 10px;
  margin: 0;
  height: 100%;
  overflow: auto;
  color: rgba(0, 0, 0, 0);
  transition: color 0.3s ease;
  box-shadow: inset 0 0 3px black;
}

ul {
  padding-inline-start: 0;
}
