.ListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  font-size: 20px;
  list-style: none;
  margin: 5px 0;
  padding: 10px 0;
  border-bottom: 1px solid rgba(8, 0, 59, 0.9);
}

.ListItem .check-uncheck-buttons {
  display: inline-block;
  width: 20%;
  padding: 0;
  margin: 0;
}

.ListItem .check-uncheck-button {
  display: inline-block;
  font-size: 1em;
  padding: 0;
  margin: 0;
  width: 50%;
  height: 30px;
}

.ListItem .delete-button {
  background-color: rgb(136, 0, 0);
  display: inline-block;
  font-size: 1em;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 30px;
}

.ListItem .delete-button:hover {
  background-color: rgb(194, 0, 0);
}

.ListItem .hidden {
  display: none;
}

.completed {
  text-decoration: line-through;
  font-style: italic;
}

.item-description {
  width: 100%;
}
