.primary-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 20px;
  padding: 0;
  height: 90%;
}

.buttons {
  box-shadow: 0 0 5px black;
  height: 10%;
  margin: 0;
  padding: 0;
  width: 100%;
  align-self: flex-end;
  z-index: 1;
}

button {
  background-color: rgba(8, 0, 59, 0.9);
  margin: 0;
  width: 50%;
  height: 100%;
  border: 0;
  font-size: 2em;
}

button:hover {
  background-color: rgba(18, 10, 71, 0.9);
}
