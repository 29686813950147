html {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(8, 0, 59, 0.9);
}

#root {
  display: flex;
  flex-direction: column;
  margin: 0;
  overflow: hidden;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(8, 0, 59, 0.9);
  -webkit-text-fill-color: antiquewhite;
}

#AddTaskModalHTML {
  -webkit-text-fill-color: antiquewhite;
}

@media only screen and (min-width: 600px) {
  #root {
    margin: 5% auto;
    width: 60%;
    height: 80%;
    max-width: 600px;
    border-radius: 1em;
    color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
}

code {
  font-family: "Lato", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
