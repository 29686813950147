.whenOffline {
  border-radius: 10px;
  height: 50%;
  width: 90%;
  margin: 50% auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  background-color: rgba(18, 10, 71, 0.9);
}

.textSadFaceIcon {
  font-weight: 700;
  font-size: 2em;
  margin: 1em;
}

.whenOffline__textAlert {
  text-align: center;
  justify-content: center;
  font-size: 15px;
  margin: 15px;
}
